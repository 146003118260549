import Glide from '@glidejs/glide'
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill'
import '@grubersjoe/slide-menu/dist/slide-menu.js'
import { autoCompleteJS } from './modules/auto-complete.js'
import './modules/back-to-top.js'
import 'sortable-tablesort/sortable.min.js'

import {
  Chart,
  LineController,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Title,
  Legend,
  Tooltip
} from 'chart.js'
import BsTooltip from 'bootstrap/js/src/tooltip'

// unicode flag emojis
polyfillCountryFlagEmojis()

// Prepare chart.js

Chart.defaults.backgroundColor = '#d9d9e0'
Chart.defaults.borderColor = '#d9d9e0'
Chart.defaults.color = '#8b8d98'

Chart.register(
  LineController,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Title,
  Legend,
  Tooltip
)

// make available globally
window.Chart = Chart

// Glide.js

const ResponsiveBullets = function (Glide, Components, Events) {
  const AutoBullets = {
    mount() {
      const NAV_SELECTOR = '[data-glide-el="controls[nav]"]'
      const CONTROLS_SELECTOR = '[data-glide-el^="controls"]'

      this._n = Components.Html.root.querySelectorAll(NAV_SELECTOR)

      //Automatically create bullets
      const totalSlides = Components.Html.slides.length
      let slidesPerView = Glide.settings.perView
      let bulletNumber = 1 + (totalSlides - slidesPerView)

      const bulletWrapper = Components.Html.root.querySelector(NAV_SELECTOR)
      if (bulletWrapper) {
        const fragment = document.createDocumentFragment()
        for (var index = 0; index < bulletNumber; index++) {
          var button = document.createElement('button')
          button.className = 'glide__bullet'
          button.setAttribute('data-glide-dir', '=' + index)
          fragment.appendChild(button)
        }
        bulletWrapper.innerHTML = ''
        bulletWrapper.appendChild(fragment)
      }

      this._c = Components.Html.root.querySelectorAll(CONTROLS_SELECTOR)

      Components.Controls.addBindings()
    },
    remount() {
      this.mount()
    }
  }

  Events.on('resize', function () {
    AutoBullets.remount()
  })

  return AutoBullets
}

// Slider (Glide.js)

var slider = document.querySelector('.glide')

if (slider) {
  new Glide('.glide', {
    autoplay: 6000,
    gap: 10,
    rewind: true,
    perView: 4,
    bound: true,
    breakpoints: {
      9999: {
        perView: 4
      },
      1024: {
        perView: 3
      },
      600: {
        perView: 2
      }
    }
  }).mount({
    ResponsiveBullets
  })
}

document.addEventListener('DOMContentLoaded', function () {
  console.log('DOM LOADED')
  // set background position
  const navbar = document.getElementById('wrapper-navbar')
  const decoBg = document.getElementById('bg-decoration')
  const headerHeight = navbar.offsetHeight
  decoBg.style.top = `${headerHeight}px`

  autoCompleteJS.input.addEventListener('selection', function (event) {
    const feedback = event.detail
    autoCompleteJS.input.blur()
    // Prepare User's Selected Value
    const permalink = feedback.selection.value['permalink']
    // Render selected choice to selection div
    // console.log(permalink);
    window.location.href = permalink
  })

  // Tooltip
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  )
  const tooltipList = [...tooltipTriggerList].map(
    (tooltipTriggerEl) => new BsTooltip(tooltipTriggerEl)
  )

  // Menu related
  const menuElement = document.getElementById('main-menu')
  const menu = new SlideMenu(menuElement, {
    showBackLink: true,
    submenuLinkAfter: ' <strong><i class="fa fa-angle-right"></i></strong>',
    backLinkBefore: '<i class="fa fa-angle-left"></i> '
  })

  // reset menu when offcanvas is closed
  const myOffcanvas = document.getElementById('navbarNavOffcanvas')
  const mainMenu = document.getElementById('main-menu')
  const mainMenuHeader = myOffcanvas.querySelector('.offcanvas-header')

  // set height of mainMenu to 100vh minus mainMenuHeader height
  mainMenu.style.height = `calc(100vh - ${
    mainMenuHeader.getBoundingClientRect().height
  }px)`

  myOffcanvas.addEventListener('hidden.bs.offcanvas', (event) => {
    menu.navigateTo(menuElement.querySelectorAll('ul')[0])
  })

  // Autocomplete related
  const navToggleBtn = document.querySelector('.search-box-toggle-btn')
  const navCloseBtn = document.querySelector('.search-box__btn-close')
  const searchBox = document.querySelector('.search-box-wrapper')
  const body = document.querySelector('body')

  if (navToggleBtn && searchBox) {
    navToggleBtn.addEventListener('click', function (event) {
      event.stopPropagation()
      const status = !searchBox.classList.contains('search-box-wrapper--modal')
      searchBox.classList.add('search-box-wrapper--modal', status)
      body.style.overflow = 'hidden'
      let searchResults = document.querySelector('.search-results')
      searchBox.classList.remove('d-none', 'd-md-block')
      document.getElementById('autoComplete').focus()
      let searchBoxHeight = document
        .getElementById('searchBox')
        .getBoundingClientRect().height
      searchResults.style.height = `calc(100vh - ${searchBoxHeight + 16}px)`
      console.log(status)
      // emit custom event
      // var event = new CustomEvent('search-box-toggle-btn-clicked', {
      //   detail: status
      // })
      // navToggleBtn.dispatchEvent(event)
    })

    // close search box if close button is clicked
    navCloseBtn.addEventListener('click', function (event) {
      event.preventDefault()
      const status = !searchBox.classList.contains('search-box-wrapper--modal')
      console.log(status)
      searchBox.classList.remove('search-box-wrapper--modal')
      searchBox.classList.add('d-none', 'd-md-block')
      body.style.overflow = 'auto'
      let searchResults = document.querySelector('.search-results')
      searchResults.style.height = 'auto'
    })
    // correct the code above if necessary
  }

  // check for resize event and then console log new width
  window.addEventListener('resize', function () {
    let windowWidth = window.innerWidth
    // let status = searchBox.classList.contains('search-box-wrapper--modal')
    windowWidth >= 768
      ? searchBox.classList.remove('search-box-wrapper--modal')
      : searchBox.classList.add('search-box-wrapper--modal')
  })

  // expand read more

  const readMoreLink = document.querySelector('.read-more-link')

  if (readMoreLink) {
    readMoreLink.addEventListener('click', function (event) {
      event.preventDefault()
      const shouldExpand = !event.target.classList.contains('expand')
      console.log(shouldExpand)
      event.target.parentElement.classList.toggle('expand', shouldExpand)
      event.target.classList.toggle('expand', shouldExpand)
    })
  }

  // const firstScrollSpyEl = document.querySelector('[data-bs-spy="scroll"]')
  let timeout
  document.addEventListener(
    'scroll',
    () => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        const activeStep = document.querySelector('.in-page-nav__link.active')
        if (activeStep) {
          activeStep.parentElement.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'start'
          })
        }
      }, 100) // Wartezeit in Millisekunden, bevor die Funktion ausgeführt wird
    },
    { passive: true }
  )

  // Ticket Manager Plugin

  let leagueMapping = {
    'all-leagues': 'all-leagues',
    'bundesliga-tickets': 'bl1',
    '2-bundesliga-tickets': 'bl2',
    'dfb-pokal-tickets': 'dfb',
    'champions-league-tickets': 'ucl',
    'europa-league-tickets': 'uel',
    'dfl-supercup-tickets': 'dfl',
    'conference-league-tickets': 'uecl',
    'super-cup-tickets': 'usc'
  }

  let currentLeague = 'all-leagues'
  let currentLocation = 'all-locations'

  function filterMatchesByLeagueAndLocation(league, homeOrAway) {
    let matches = document.querySelectorAll('.matches > div')
    let hasMatches = false

    let noMatchesDiv = document.getElementById('noMatches')
    noMatchesDiv.style.display = 'none'

    matches.forEach((match) => {
      match.style.display = 'none'
      match.classList.remove('d-flex')

      let matchesLeague =
        league === 'all-leagues' || match.classList.contains(league)
      let matchesLocation =
        homeOrAway === 'all-locations' ||
        (homeOrAway === 'is-home' && match.classList.contains('is-home')) ||
        (homeOrAway === 'is-away' && match.classList.contains('is-away'))

      if (matchesLeague && matchesLocation) {
        match.classList.add('d-flex')
        match.style.display = '' // Spiel anzeigen
        hasMatches = true
      }
    })

    console.log(hasMatches)

    if (!hasMatches) {
      noMatchesDiv.style.display = 'block' // Nachricht anzeigen
    }
  }

  function updateFilterElements() {
    document.querySelectorAll('.filter-list li').forEach(function (li) {
      li.classList.remove('active')
    })
    if (currentLeague === 'all-leagues') {
      document
        .querySelector("#leagueFilter li a[data-league='all-leagues']")
        .parentElement.classList.add('active')
    } else {
      document
        .querySelector(
          "#leagueFilter li a[data-league='" + currentLeague + "']"
        )
        .parentElement.classList.add('active')
    }

    if (currentLocation === 'all-locations') {
      document
        .querySelector("#locationFilter li a[data-location='all-locations']")
        .parentElement.classList.add('active')
    } else {
      document
        .querySelector(
          "#locationFilter li a[data-location='" + currentLocation + "']"
        )
        .parentElement.classList.add('active')
    }
  }

  function resetAllFilters() {
    // Filter auf die Standardwerte zurücksetzen
    currentLeague = 'all-leagues'
    currentLocation = 'all-locations'
    filterMatchesByLeagueAndLocation(currentLeague, currentLocation)
    updateFilterElements()
    updateGameCountAndResetButton() // Anzahl der Spiele und Button erneut aktualisieren
  }

  function updateGameCountAndResetButton() {
    let visibleMatches = Array.from(
      document.querySelectorAll('.matches > div')
    ).filter((match) => match.style.display !== 'none')
    // Spiele-Anzahl aktualisieren
    document.querySelector('.games-count-number').textContent =
      visibleMatches.length

    // "Alle Filter zurücksetzen"-Button anzeigen, wenn Filter aktiv sind
    let resetButton = document.getElementById('resetFilters')
    if (
      currentLeague !== 'all-leagues' ||
      currentLocation !== 'all-locations'
    ) {
      resetButton.style.display = 'inline' // Button sichtbar machen
    } else {
      resetButton.style.display = 'none' // Button verstecken
    }
  }

  // Funktion zum Setzen des Filters basierend auf der Klasse
  function setFilter(
    league = currentLeague,
    homeOrAway = currentLocation,
    $scroll = 0
  ) {
    currentLeague = league // Update current league filter
    currentLocation = homeOrAway // Update current location filter

    filterMatchesByLeagueAndLocation(league, homeOrAway)
    updateGameCountAndResetButton()
    updateFilterElements()

    // handle scroll
    let matchesHeadline = document.getElementById('tickets')
    let navBar = document.querySelector('#in-page-nav-container')
    // get height ov navBar
    let navBarHeight = navBar.getBoundingClientRect().height
    matchesHeadline.style.scrollMargintop = `calc(${navBarHeight} + 1rem)`
    $scroll &&
      matchesHeadline.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
  }

  // Event Listener für "Alle Filter zurücksetzen"-Button
  const resetButton = document.getElementById('resetFilters')
  if (resetButton) {
    resetButton.addEventListener('click', function (event) {
      event.preventDefault()
      resetAllFilters()
    })
  }

  // Event Listener für Klicks auf die Filter-Links (Wettbewerbe)
  document.querySelectorAll('#leagueFilter li a').forEach(function (anchor) {
    anchor.addEventListener('click', function (event) {
      event.preventDefault()
      let leagueShortcut = this.getAttribute('data-league')
      setFilter(leagueShortcut, currentLocation, 1)
      // set URL-Hash-Parameter
      window.location.hash = Object.keys(leagueMapping).find(
        (key) => leagueMapping[key] === leagueShortcut
      )
    })
  })

  // Event Listener für Klicks auf die Filter-Links (Spielort)
  document.querySelectorAll('#locationFilter li a').forEach(function (anchor) {
    anchor.addEventListener('click', function (event) {
      if (this.classList.contains('disabled')) {
        // Wenn der Link deaktiviert ist, kein Klick zulassen
        event.preventDefault()
        return
      }
      event.preventDefault()
      let homeOrAway = this.getAttribute('data-location')
      setFilter(currentLeague, homeOrAway)
    })
  })

  // Beim Laden der Seite den Filter basierend auf dem URL-Hash setzen
  if (window.location.pathname.includes('/klubs/')) {
    var hash = window.location.hash.substring(1)
    // Entfernt das '#' am Anfang
    if (hash && Object.keys(leagueMapping).includes(hash)) {
      setFilter(leagueMapping[hash], currentLocation, 1)
    } else {
      setFilter('all-leagues', currentLocation, 0)
    }
  }

  // add shadow to navbar when class sticky is added
  const inPageNav = document.querySelector('.in-page-nav-container')

  // only if element exists
  inPageNav &&
    window.addEventListener(
      'scroll',
      () => {
        const stickyTop = parseInt(window.getComputedStyle(inPageNav).top)
        const currentTop = inPageNav.getBoundingClientRect().top
        inPageNav.classList.toggle('shadow-sm', currentTop === stickyTop)
      },
      { passive: true }
    )

    // Sort table on load
    const el = document.getElementById('general-admission-ticket')
    // without id:
    // const el = document.querySelector('.sortable th:first-child')
    // const el = document.querySelector('.sortable th:nth-child(2)')
    // const el = document.querySelectorAll('.sortable')[3].querySelector('th:nth-child(7)')
    // etc.
    if (el) {
      el.click()
    }
})
