import autoComplete from '@tarekraafat/autocomplete.js'

// The autoComplete.js Engine instance creator
export const autoCompleteJS = new autoComplete({
  searchEngine: (query, record) => {
    query
      .replace(/\s+/g, ' ')
      .trim()
      .split(' ')
      .forEach(function (substring) {
        var regex1 = RegExp(substring, 'i')
        if (regex1.test(record)) {
          record = record.replace(regex1, '!*$&*!')
        }
      })
    return record.replaceAll('!*', '<mark>').replaceAll('*!', '</mark>')
  },
  data: {
    src: async (query) => {
      try {
        // Loading placeholder text
        document
          .getElementById('autoComplete')
          .setAttribute('placeholder', 'Lädt...')
        // Fetch External Data Source
        let formData = new FormData()
        formData.append('query', query)
        const source = await fetch(
          `//${window.location.host}/wp-content/themes/bulitickets/searchjson.php`,
          {
            method: 'POST',
            body: formData
          }
        )
        const data = await source.json()
        // Post Loading placeholder text
        document
          .getElementById('autoComplete')
          .setAttribute('placeholder', autoCompleteJS.placeHolder)
        // Returns Fetched data
        return data
      } catch (error) {
        return error
      }
    },
    keys: ['label'],
    // cache: true,
    cache: false,
    filter: (list) => {
      // Filter duplicates
      // incase of multiple data keys usage
      const filteredResults = Array.from(
        new Set(list.map((value) => value.match))
      ).map((label) => {
        return list.find((value) => value.match === label)
      })

      return filteredResults
    }
  },
  placeHolder: 'Klub, Wettbewerb, Spiel,...',
  resultsList: {
    tag: 'div',
    id: 'autoComplete_list',
    class: 'search-results',
    destination: '#searchBox',
    element: (list, data) => {
      let categories = {}
      ;[...list.children].forEach((item) => {
        if ('category' in item.dataset) {
          let category = item.dataset.category
          if (!(category in categories)) {
            categories[category] = document.createElement('ul')
            categories[category].dataset.category = category
          }
          categories[category].appendChild(item)
        }
      })
      Object.keys(categories).forEach((category) => {
        const categoryHead = document.createElement('header')
        categoryHead.innerHTML = category
        list.appendChild(categoryHead)
        list.appendChild(categories[category])
      })
    },
    maxResults: 30,
    tabSelect: true,
    noResults: true
  },
  resultItem: {
    class: 'd-flex justify-content-between',
    element: (item, data) => {
      let markup

      markup = `<div class="d-flex flex-column justify-content-start">
          <span class="d-block">${data.match}</span>`

      markup +=
        data.value['category'] === 'Spiele'
          ? `<span class="match_meta">${data.value['date_info']}`
          : ``

      markup +=
        data.value['category'] === 'Spiele' && data.value['venue_info']
          ? ` - ${data.value['venue_info']}</span>`
          : `</span>`

      markup += '</div>'

      markup +=
        data.value['category'] === 'Spiele'
          ? `<span style="display: flex; align-items: center; font-size: 13px; font-weight: 100; text-transform: uppercase; color: rgba(0,0,0,.2);">
          <span class="badge light ${
            data.value['competition_shortcut']
          }">${data.value['competition_shortcut'].toUpperCase()}</span>
        </span>`
          : ``

      item.innerHTML = markup

      if ('category' in data.value) {
        item.dataset.category = data.value['category']
      }
    },
    highlight: true
  },
  events: {
    input: {
      focus: () => {
        if (autoCompleteJS.input.value.length) autoCompleteJS.start()
      }
    }
  }
})



// Blur/unBlur page elements
// const action = (action) => {
//   const navbarBrand = document.querySelector('.navbar-brand')
//   const teaser = document.querySelector('.teaser-box')
//   const trendingNav = document.querySelector('.trending-nav')
//   const iconsNav = document.querySelector('.icons-nav')

//   if (action === 'dim') {
//     teaser.style.opacity = 1
//     navbarBrand.style.opacity = 1
//     trendingNav.style.opacity = 1
//     iconsNav.style.opacity = 1
//   } else {
//     teaser.style.opacity = 0.1
//     navbarBrand.style.opacity = 0.2
//     trendingNav.style.opacity = 0.1
//     iconsNav.style.opacity = 0.1
//   }
// }

// // Blur/unBlur page elements on input focus
// ;['focus', 'blur'].forEach((eventType) => {
//   autoCompleteJS.input.addEventListener(eventType, () => {
//     // Blur page elements
//     // todo: make dependent on input length > 0
//     if (eventType === 'blur') {
//       action('dim')
//     } else if (eventType === 'focus') {
//       // unBlur page elements
//       action('light')
//     }
//   })
// })
